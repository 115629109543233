<div class="horizontal-padding">
  <h4>User Terms</h4>

  <p>
    RealtyBid.com ("RealtyBid") has agreements with various individuals and businesses ("Sellers") to market and advertise certain residential and commercial
    Properties and/or financial instruments ("Property" or "Properties") through the RealtyBid auction Site (the “Site”). All Properties on RealtyBid's Site are
    offered subject to the Sellers' approval of the sales price and final terms and conditions of sale.
  </p>

  <p>THIS SITE IS PROVIDED ONLY FOR THE USE OF VIEWING AND/OR BIDDING ON THOSE PROPERTIES LISTED HEREIN AND ANY OTHER USE IS PROHIBITED.</p>

  <p><b> ALL USERS MUST AGREE TO THE FOLLOWING USER TERMS: </b></p>

  <b>1. Site Usage</b>
  <p>
    You agree by using the Site to these User Terms. Bidding requires user registration on the Site. You further agree to use the Site in a manner consistent
    with the purpose of the Site. Registration for the Site and Bidding is currently free and is necessary to unlock many of the Site's most useful features;
    please review <a rel="noreferrer noopener" href="/help-center/common-questions/">How to Bid</a> and
    <a [routerLink]="['/help-center/site-features/']" target="_blank" rel="noreferrer noreopen">Helpful Site Features</a>. You agree as a registered user to not
    share your user credentials or transfer them to another party.
  </p>

  <p>
    RealtyBid bears no responsibility for the condition or value of any Property listed for auction or ensuring any bid is placed. If it is determined that
    there was a system issue that may have prevented a bid from being placed, RealtyBid reserves the right to review all bids and possibly re-open bidding on a
    Property. This determination will be made on a case-by-case basis and is at the sole discretion of the company. The intent of RealtyBid is to always provide
    a fair, level, and transparent bidding environment for our consumer audience.
  </p>

  <b>2. Online Property Bidding</b>

  <p>
    If you elect to bid in an online Property auction, you must be registered to do so; however, you do not have to be registered to view auction Properties or
    bids for an online auction. All bids are open and displayed for public viewing unless otherwise noted. You can bid on Properties either from a Property's
    bid page or from the current bids link on your account profile page. The current bids area only has abbreviated information about each Property but is a
    useful tool if you are monitoring multiple Properties.
  </p>

  <p>
    In some cases, you will need to follow the additional specific bid instructions to bid on that Property. The use of BidAssist, as explained in
    <a routerLink="/help-center/site-features/" rel="noreferrer noopener" target="_blank">Helpful Site Features</a>, is highly recommended when bidding in
    RealtyBid online auctions. This feature allows the computer system to bid for you up to your maximum bid. It is protection against a last-minute, higher
    dollar amount bid that would prevent you from having the winning bid for a Property. Also, because different computers and mobile devices have different
    connection speeds, waiting until the last seconds of an auction to place your bid manually is not recommended. The countdown clock on RealtyBid.com should
    be considered accurate but not exact, and BidAssist can help ensure your maximum bid is placed before the auction closes. It is highly recommended that if
    you intend to place a bid, you should do so early to get a feel for the bidding process.
    <b
      >If you intend to bid on a Property, we strongly suggest that you register at least 72 hours prior to the close of the bidding, just as a precaution
      against any potential registration problems.</b
    >
  </p>

  <p>
    <b>Bid Presentation to the Seller: </b>If bidding for a Property meets or exceeds the minimum acceptable sales price established by the Seller (the
    “Reserve”) during the online bidding process, the Property will be coded as ‘pending’ until the buyer-executed purchase agreement and deposit funds are
    received from the winning bidder. At that point, the bid will be presented to the Seller, and, if that winning bid is accepted, the Property will be coded
    as ‘sold’ and the closing process will begin. While the Property is coded as pending and up until the Property is designated as sold, additional higher bids
    may be presented. Notwithstanding the above, the Seller always has the right to a final review of all bids received. The Seller may accept or reject any bid
    regardless of whether the reserve price has been met.
  </p>

  <p>
    <b>Post Bids:</b>
    Each RealtyBid online auction will run until the initial auction countdown clock expires, regardless of whether Reserve is met. If the Reserve is not met at
    the end of the initial auction, the Property moves into 'post bidding’. During post bidding, additional bids can be submitted. Unlike the initial auction
    phase, bidding in the post bidding phase ends once the Reserve is met or the Seller accepts a bid, regardless of any time left on the auction countdown
    clock. Once an online bidding event concludes, neither the Seller nor RealtyBid is under any obligation to negotiate with any bidder. All Properties will be
    sold through post bidding on a "first come, first served" basis by the Seller.
  </p>

  <p>
    <b>Highest and Best Offer Process: </b>
    If more than one bid is submitted in what is considered an acceptable price range by the Seller or RealtyBid, a highest and best offer round may be held to
    make sure each bidder receives a fair opportunity to place their highest possible bid. This process may be utilized even after a Property is coded as
    pending. The highest and best offer process will be utilized at Seller and RealtyBid’s sole discretion.
  </p>

  <p>
    <b>Final Approval by Seller: </b>
    Each Seller reserves the right to have final approval of all purchase contracts submitted by any broker or bidder, regardless of information provided by
    this Site, any broker, or any RealtyBid employee or affiliate to the contrary.
  </p>

  <b>3. Terms of Use</b>
  <p>
    You shall act with honesty and integrity while on using the Site; otherwise, user privileges shall be revoked. Each bidder shall review and adhere to the
    terms of sale for each individual Property listed on the Site. RealtyBid may suspend your account and seek legal remedies if the RealtyBid staff suspects
    you have committed or engaged in fraud or fraudulent activities relating to transactions on the Site. Any user making fraudulent bids on this Site or
    defaulting on Properties won may be banned from the Site indefinitely without recourse.
  </p>

  <b>4. User Eligibility</b>
  <p>
    RealtyBid's services are available only to individuals who can form legally binding contracts under applicable law. RealtyBid's services are not available
    to minors or to previously suspended RealtyBid users. If you register as a business entity, when you place a bid, you represent that you have the authority
    to bind the entity to the agreement in question and other real estate-related agreements.
  </p>

  <b>5. RealtyBid Fairness Doctrine</b>
  <p>
    RealtyBid prides itself on offering Properties on a fair and level playing field and allowing bidders an equal and fair opportunity to compete. If it is
    determined by RealtyBid that a Property is won unfairly, for any reason, RealtyBid reserves the right to 1) re-offer the Property in a new round of bidding,
    2) request a highest and best offer among the bidders or 3) return the Property to the Seller and not sell it to any bidder.
  </p>

  <b>6. Acknowledgement & Disclaimers</b>
  <p>
    To the extent you utilize RealtyBid’s online services, you acknowledge and agree that RealtyBid makes no guarantee that communications or transactions
    conducted online will be absolutely secure. You further acknowledge and agree that there may be system failures that may limit your ability to use the
    online services. You agree to assume all risk and liability arising from your use of RealtyBid’s online services, including the risk of breach in the
    security of the communications or transactions you conduct with RealtyBid online. YOU UNDERSTAND AND AGREE THAT REALTYBID’S ONLINE SERVICES ARE PROVIDED "AS
    IS," WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
    PARTICULAR PURPOSE OR OTHERWISE.
  </p>

  <b>7. Use of Services & Website Content</b>
  <p>
    You may view, copy, or print pages from this Site solely for personal, noncommercial purposes. You may not otherwise use, modify, copy, print, display,
    reproduce, distribute or publish any information from this Site without the express written permission of RealtyBid. At any time, RealtyBid may, without
    notice, make changes to this Site or to the online services or products described and/or offered on this Site.
  </p>
  <p>
    RealtyBid reserves the right to modify, reject or eliminate any information residing on or transmitted to its server that it, in its sole discretion,
    believes is unacceptable or in violation of these terms and conditions and to suspend or end your service for any operational or governmental reason or
    violation of these terms and conditions.
  </p>

  <b>8. Fees & Services</b>
  <p>
    Registration for the RealtyBid Site is free, but a $250 deposit is required to bid in an online auction (a “Bid Deposit”). This Bid Deposit is a hold placed
    on a credit or debit card. This hold allows RealtyBid to confirm that your account is valid and that you are placing a bid with sincere intent to purchase
    the Property if you are the winning Bidder. The hold will not appear as a charge on your credit card or bank statement unless you are the winning bidder and
    fail to complete the contract execution process.
  </p>

  <p>
    Additionally, when you win a Property, you must pay a buyer’s deposit of 5% of the winning bid amount or $2,000, whichever is greater, unless stated
    otherwise on the Property’s bidding terms and conditions. We reserve the right to change the bidding terms and conditions at any time relating to fees and
    other items mentioned or not mentioned in this published document. We may in our sole discretion change any or all our services at any time. If a change
    occurs in a particular service, the fees for that service are effective at the introduction of the service. If a Property is subject to an internet
    transaction fee or buyer's premium fee, these fees will be disclosed in the Property biding terms and conditions and will be paid by the BUYER at closing.
    The bid amount posted on the Site will not reflect these fees added to the bid amount, but the purchase agreement shall include the fee through a RealtyBid
    addendum, which shall be attached to the purchase agreement and signed by both buyer and Seller.
  </p>

  <b>9. Links</b>
  <p>
    This Site may include hyperlinks to websites maintained or controlled by third parties. RealtyBid is not responsible for and does not endorse the contents
    of or use of any of the products or services offered by these third-party websites.
  </p>

  <b>10. Disclaimers</b>
  <p>
    As the user of this Site and any RealtyBid online services, you assume all responsibility and risk for the use of this server and the internet in general.
    RealtyBid, its parent and affiliates disclaim all warranties, representations or endorsements, express or implied, with regard to the information accessed
    from, or via, this server or the internet, including, but not limited to, all implied warranties of merchantability, fitness for a particular purpose, or
    noninfringement. RealtyBid does not assume any legal liability or responsibility for the accuracy, completeness, or usefulness of any information, product,
    or process disclosed on the Site or other material accessible from the Site. In no event shall RealtyBid be liable for any special, indirect, or
    consequential damages or any damages whatsoever resulting from loss of use, loss of data, or loss of profits, whether in an action of contract, negligence
    or other tortious action, arising out of or in connection with the use or performance of the information on the Site or the internet generally.
  </p>

  <b>11. No Warranties</b>
  <p>
    Any RealtyBid material on this server may include technical inaccuracies or typographical errors. RealtyBid is not responsible for any damages incurred,
    consequential or otherwise. RealtyBid has the right to make changes and updates to any information contained within this server without prior notice.
  </p>
  <p>
    Information is provided on the Site on an "as is" and "as available" basis without warranties of any kind, either express or implied, including, but not
    limited to, warranties of title, noninfringement, or implied warranties of merchantability or fitness for a particular purpose. No advice or information
    given by RealtyBid, its affiliates or their respective employees shall create any warranty hereunder. Neither RealtyBid nor its affiliates warrant that the
    information on the Site or on the internet in general is uninterruptable or error free or that any information, software or other material accessible from
    the Site is free of viruses or other harmful components.
  </p>

  <b>12. Modifications</b>
  <p>
    These terms and conditions may not be changed or supplemented in individual instances except in a writing that is signed by an authorized representative of
    RealtyBid.
  </p>

  <b>13. Discrepancies in Advertisements</b>
  <p>
    If there are any discrepancies between the terms and conditions outlined on the Site and the terms outlined in the purchase agreement, the terms in the
    purchase agreement shall prevail, so read the purchase agreement carefully. All dates and times listed on the Site supersede any advertisements or printed
    statements.
  </p>

  <b>14. Online Transaction Venue</b>
  <p>
    RealtyBid provides an online real estate transaction venue that allows for the bidding and transfer of Properties via a public forum. RealtyBid does not
    hold itself out to be a real estate broker or agent, nor does it claim to act on behalf of buyers or Sellers, even though it does hold real estate and
    auctioneer licenses in numerous states. RealtyBid provides an efficient and effective platform that allows Sellers to advertise their Properties to willing
    and able buyers. All listing brokers identified with a Property on this Site are agents of the Seller, not the buyer. If a disruption of Internet bidding
    service (such as a power outage or other technical occurrence of any kind) occurs at the end of an online auction event, RealtyBid reserves the right to
    extend that event for a reasonable period as decided by RealtyBid to provide bidders a fair chance to compete for the purchase of a Property.
  </p>
</div>
