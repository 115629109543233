import { environment } from '$env';
import { Models } from '$models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NtsStateManagementService, ntsUIStoreCreator } from '@ntersol/state-management';
import { NtsState } from '@ntersol/state-management/lib/state.models';
import { removeNils } from '@ntersol/utils';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable, ReplaySubject, debounceTime, distinctUntilChanged, filter, map, mergeMap, startWith, take, tap } from 'rxjs';
import { AnalyticsService } from '../../../../shared/services';
import { SessionStorageService, StorageKeys } from '../../../../shared/services/session-storage.service';
import { SearchModels, SearchStoreService } from '../../../../shared/stores';
import { sortbyPartialMatches } from '../utils';

type EntityPropertySearchResponse = NtsState.EntityApiState<Models.PropertySearchResponse, any>;

export enum SearchType {
  noSearch = 'noSearch',
  home = 'home',
  location = 'location',
  filter = 'filter',
  map = 'map',
}
@Injectable({
  providedIn: 'root',
})
export class HousesApiStoreService {
  searchType$ = new BehaviorSubject<SearchType>(SearchType.noSearch);
  storeSearchUrl = '';
  private store = this.sm.createBaseStore({ apiUrlBase: environment.endpoints.apiUrl + '' });

  readonly resultsUiStore = ntsUIStoreCreator<{ results: Models.PropertySearchResponse[] }>({ results: [] });
  readonly searchPayloadUiStore = ntsUIStoreCreator<{ payload: string }>({ payload: '' });

  private readonly _bidHistory$ = new ReplaySubject<Models.PropertyBidHistoryResponse[]>(1);
  public readonly bidHistory$ = this._bidHistory$.asObservable();

  public searchTerm$ = new BehaviorSubject('');

  /**
   * once the ntersol request issue fixed, remove and rollback the code
   * */
  //  searchResults = this.store<Models.PropertySearchResponse>({
  //   apiUrl: '/search/listings',
  //   uniqueId: 'ITEM_ID',
  //   autoLoad: false,
  //   map: {
  //     // Remove empty/nill image paths
  //     get: (listings: Models.PropertySearchResponse[]) => {
  //       return this.cleandedListings(listings);
  //     },
  //   },
  // });

  /**
   * this is an override function from the original *searchResults* by a request issue in the ntersol store.
   * once it fixed, remove and rollback the code
   * */
  private readonly _searchResultsOverride$ = new BehaviorSubject<EntityPropertySearchResponse>({} as EntityPropertySearchResponse);
  searchResultsOverride$ = this._searchResultsOverride$.asObservable();

  readonly searchResults$ = this.resultsUiStore.select$('results');
  readonly searchPayload$ = this.searchPayloadUiStore.select$('payload');

  spotlights = this.store<Models.PropertySpotlightsResponse>({
    apiUrl: 'public/search/spotlights',
    uniqueId: 'ITEM_ID',
  });

  propertyDetails = this.store<Models.PropertyDetailsResponse>({
    apiUrl: 'public/property',
    autoLoad: false,
    map: {
      get: (r: Models.PropertyDetailsResponse) => (r ? removeNils(r) : r),
    },
  });

  fees$ = this.propertyDetails.select$.pipe(
    map(d => d?.ITEM_ID),
    filter(d => !!d),
    mergeMap(id => this.http.get<Models.PropertyFeesResponse>(environment.endpoints.apiUrl + `public/property/${id}/fees`)),
    startWith(null),
  );

  updateBidHistory(itemId: number): void {
    this.http
      .get<Models.PropertyBidHistoryResponse[]>(environment.endpoints.apiUrl + `public/property/${itemId}/bidhistory`)
      .pipe(
        map((res): Models.PropertyBidHistoryResponse[] => {
          if (!res) {
            return [];
          }
          return res.map(item => {
            return {
              ...item,
              // This property added for using by UI components (e.g. table component)
              BIDTIME: dayjs(item.BIDDATE).format('h:mm A z'),
            };
          });
        }),
        take(1),
      )
      .subscribe(response => {
        this._bidHistory$.next(response);
      });
  }

  searchInSurroundingArea$ = new BehaviorSubject(false);
  surroundingArea$ = new BehaviorSubject<string>('');
  private surroundingRetry = 0;
  canSearchSurrounding = false;
  private readonly locationStorage = this.sessionStorageService.getStorage<string>(StorageKeys.searchSurroundingLocation);

  constructor(
    private sm: NtsStateManagementService,
    private http: HttpClient,
    private searchStoreService: SearchStoreService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  /**
   * this is an override function from the original *searchResults* by a request issue in the ntersol store.
   * once it fixed, remove and rollback the code
   * */
  searchResults(apiUrlAppend: string) {
    const response: EntityPropertySearchResponse = {
      entities: {} as Record<string | number, Models.PropertySearchResponse>,
      data: [],
      loading: true,
      modifying: false,
      error: undefined,
      errorModify: undefined,
    };
    this._searchResultsOverride$.next(response);

    return this.http.get<Models.PropertySearchResponse[]>(`${environment.endpoints.apiUrl}public/search/listings${apiUrlAppend}`).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map((listings: Models.PropertySearchResponse[]) => this.cleandedListings(listings)),
      tap(listings => {
        if (listings.length > 0) {
          this.handleResults(listings);
        } else if (!this.canSearchSurrounding || this.surroundingRetry === 3) {
          this.handleResults(listings);
        } else {
          this.hangleSurroundingSearch();
        }
      }),
    );
  }

  private hangleSurroundingSearch() {
    this.surroundingRetry++;
    const location$ = this.searchStoreService.searchLocation$;

    location$
      .pipe(
        take(1),
        tap(area => this.setSurroundingArea(area)),
      )
      .subscribe();
    this.searchInSurroundingArea$.next(true);
  }

  private handleResults(listings: Models.PropertySearchResponse[]) {
    const searchTerm = this.searchTerm$.getValue();

    let sortedListings = listings;
    if (searchTerm) {
      sortedListings = sortbyPartialMatches(listings, searchTerm);
    }
    const response: EntityPropertySearchResponse = {
      entities: {} as Record<string | number, Models.PropertySearchResponse>,
      data: sortedListings,
      loading: false,
      modifying: false,
      error: undefined,
      errorModify: undefined,
    };
    this._searchResultsOverride$.next(response);
    this.surroundingRetry = 0;
    this.canSearchSurrounding = false;
    this.resultsUiStore.update({ results: sortedListings });
    this.analyticsService.customEvent({
      'event': 'Property Search',
    });
  }

  getCurrentBidData(itemId: number): Observable<Models.PropertyCurrentBidResponse> {
    return this.http.get<Models.PropertyCurrentBidResponse>(`${environment.endpoints.apiUrl}public/property/${itemId}/currentbid`);
  }

  private cleandedListings(listings: Models.PropertySearchResponse[]): Models.PropertySearchResponse[] {
    const cleandedListings = Array.isArray(listings) ? listings.map(l => ({ ...l, PHOTOS: l.PHOTOS?.filter(l2 => l2.IMAGE_PATH) })) : [];
    return cleandedListings;
  }

  private setSurroundingArea(area: SearchModels.Location): void {
    let location;
    if (area?.city && area?.state) {
      location = `${area.city}, ${area.state}`;
      this.locationStorage.saveData(location);
    } else {
      location = this.locationStorage.getData();
    }
    return this.surroundingArea$.next(location);
  }

  stopSurroundedArea(canSearch = false) {
    if (this.canSearchSurrounding) {
      return;
    }
    this.surroundingRetry = 0;
    if (!canSearch) {
      this.locationStorage.clearAll();
      this.surroundingArea$.next('');
    }
    this.searchInSurroundingArea$.next(false);
    this.canSearchSurrounding = canSearch;
  }

  clearResults() {
    this._searchResultsOverride$.next({} as EntityPropertySearchResponse);
  }

  setSearchType(searchType: SearchType) {
    this.searchType$.next(searchType);
  }
}
