import { Models } from '$models';
import { SearchModels } from '../../../../shared/stores';
import { maxApiResults, SearchType } from '../stores';
import { removeNils } from './remove-nils.util';

/**
 * Convert the viewport and filters into the query params needed by the search api
 * @param viewport
 * @param filters
 * @returns
 */
export const viewportToApiRequestUrl = (
  viewport?: google.maps.LatLngBoundsLiteral | null,
  filters?: Models.QueryParamsSearchFilters | null,
  location?: SearchModels.Location | null,
  sortBy?: 'asc' | 'desc',
  searchType?: SearchType,
) => {
  let apiUrlAppend = `?`;
  if (viewport) {
    // Create request url
    apiUrlAppend =
      apiUrlAppend + `upperLeftLat=${viewport?.north}&lowerRightLat=${viewport?.south}&upperLeftLong=${viewport?.west}&lowerRightLong=${viewport?.east}`;
  }
  // Prevents the user from scrolling and seeing results out of state
  // Only add state param if is state search
  if (location?.state && !location?.city) {
    apiUrlAppend = `${apiUrlAppend}&stateList=${location.state}`;
  }

  // Placeholders for static props
  apiUrlAppend += `&pageSize=${maxApiResults}&currentPage=1`;
  if (filters) {
    const cleanedFilters = convertFiltersFromQueryParamsToListingsRequest(filters);
    if (!!cleanedFilters) {
      for (const [key, value] of Object.entries(cleanedFilters)) {
        if (Array.isArray(value)) {
          apiUrlAppend += `&${key}=${value.join(',')}`;
        } else {
          apiUrlAppend += `&${key}=${value}`;
        }
      }
    }
  }
  // Adds sorting option ASC/DESC
  if (sortBy) {
    apiUrlAppend = `${apiUrlAppend}&sortBy=DATE_END ${sortBy.toUpperCase()}`;
  }
  if (searchType) {
    apiUrlAppend = `${apiUrlAppend}&searchType=${searchType}`;
  }

  return apiUrlAppend;
};

function convertFiltersFromQueryParamsToListingsRequest(filters: Models.QueryParamsSearchFilters): Models.PropertySearchRequest | null {
  if (!filters) {
    return null;
  }
  let resultObject = {};
  if (filters.hasOwnProperty('TYPE') && !!filters.TYPE) {
    resultObject = { ...resultObject, TYPE: filters.TYPE };
  }

  if (filters.hasOwnProperty('CATEGORYLIST') && !!filters.CATEGORYLIST) {
    resultObject = { ...resultObject, CATEGORYLIST: filters.CATEGORYLIST };
  }

  if (filters.hasOwnProperty('MINBATHS') && filters.MINBATHS !== undefined && filters.MINBATHS !== null) {
    resultObject = { ...resultObject, MINBATHS: filters.MINBATHS };
  }

  if (filters.hasOwnProperty('MINBEDS') && filters.MINBEDS !== undefined && filters.MINBEDS !== null) {
    resultObject = { ...resultObject, MINBEDS: filters.MINBEDS };
  }

  if (filters.hasOwnProperty('MORE')) {
    const moreObj = filters.MORE;
    if (moreObj?.hasOwnProperty('ENDINGTODAY')) {
      if (moreObj?.ENDINGTODAY === true) {
        resultObject = { ...resultObject, ENDINGTODAY: 1 };
      }
    }

    if (moreObj?.hasOwnProperty('RESERVEMET')) {
      if (moreObj?.RESERVEMET === true) {
        resultObject = { ...resultObject, RESERVEMET: 1 };
      }
    }

    if (moreObj?.hasOwnProperty('NOBUYERPREMIUMS')) {
      if (moreObj?.NOBUYERPREMIUMS === true) {
        resultObject = { ...resultObject, NOBUYERPREMIUMS: 1 };
      }
    }

    if (moreObj?.hasOwnProperty('POSTAUCTIONS')) {
      if (moreObj?.POSTAUCTIONS === true) {
        resultObject = { ...resultObject, POSTAUCTIONS: 1 };
      }
    }

    if (moreObj?.hasOwnProperty('FINANCING')) {
      if (Array.isArray(moreObj.FINANCING)) {
        if (moreObj.FINANCING[0] === true) {
          resultObject = { ...resultObject, FINANCING: 'Y' };
        } else if (moreObj.FINANCING[1] === true) {
          resultObject = { ...resultObject, FINANCING: 'N' };
        }
      }
    }

    if (moreObj?.hasOwnProperty('OCCUPANCY') && moreObj.OCCUPANCY) {
      resultObject = { ...resultObject, OCCUPANCY: [moreObj.OCCUPANCY.filter(el => !!el && el[0]).map(el => el[0])] };
    }
  }
  return removeNils(resultObject);
}

function countPartialsMatches(item: string, search: string): number {
  const itemTokens = item.toLowerCase().split(' ');
  const searchTokens = search.toLowerCase().split(',')[0].split(' ');

  let matches = 0;

  for (const sToken of searchTokens) {
    if (itemTokens.some(iToken => iToken.includes(sToken))) {
      matches++;
    }
  }

  return matches;
}

export function sortbyPartialMatches(listings: Models.PropertySearchResponse[], searchTerm: string) {
  return listings.sort((a, b) => {
    const aMatches = countPartialsMatches(a.ADDRESS1 || '', searchTerm);
    const bMatches = countPartialsMatches(b.ADDRESS1 || '', searchTerm);
    return bMatches - aMatches;
  });
}
